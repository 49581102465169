<script>
import { abbreviateClusterName } from '@shell/utils/cluster';

export default {
  props: {
    cluster: {
      type:     Object,
      required: true,
    },
  },
  computed: {
    isEnabled() {
      return !!this.cluster?.ready;
    },
    showLocalIcon() {
      return this.cluster.isLocal && !this.cluster.isHarvester && !this.cluster.badge?.iconText;
    },
    badgeLogoBorderBottom() {
      const color = this.cluster.badge?.color;

      return color ? `4px solid ${ color }` : '';
    }
  },
  methods: {
    smallIdentifier(input) {
      if (this.cluster.badge?.iconText) {
        return this.cluster.badge?.iconText;
      }

      if (this.cluster.isLocal && !this.cluster.badge?.iconText) {
        return undefined;
      }

      return abbreviateClusterName(input);
    }
  }
};
</script>
        <template>
          <div v-if="cluster" class="cluster-icon-menu">
            <div class="cluster-badge-logo" :class="{ 'disabled': !isEnabled }" :style="{ borderBottom: badgeLogoBorderBottom }">
              <span class="cluster-badge-logo-text"> {{ smallIdentifier(cluster.label) }} </span>
              <svg v-if="showLocalIcon" class="cluster-local-logo" xmlns="http://www.w3.org/2000/svg" width="1551" height="769" viewBox="0 0 1551 769" style="enable-background:new 0 0 100 100;fill: white" fill="none" xml:space="preserve">
                <path d="M505.241 2.5C499.041 6.2 496.341 11 495.941 19.2L495.541 26.1L491.341 25.5C480.141 23.9 404.741 16 401.241 16C395.941 16 390.041 19.5 386.341 24.8C383.641 28.6 383.341 29.9 383.241 36.3C383.241 45 385.641 49.7 392.141 53.5C396.041 55.8 400.141 56.4 441.841 61C466.841 63.7 489.141 66.2 491.541 66.6L495.741 67.1V96.1V125H466.241C434.141 125 433.841 125.1 428.541 130.7C427.141 132.2 425.441 135.3 424.841 137.5C423.241 143.8 410.741 203.8 410.741 205.5C410.741 206.8 423.241 207 517.741 207H624.741V171.6C624.741 136.5 624.741 136.3 622.441 132.9C621.241 131.1 618.641 128.5 616.841 127.3C613.541 125.1 612.841 125 588.141 125H562.741V99.7V74.4L578.041 76.1C598.241 78.5 604.441 78.5 608.741 76.1C616.741 71.8 621.241 61.1 618.741 52C617.141 46.2 610.341 39.4 605.041 38.4C602.941 38 592.741 36.7 582.241 35.6L563.241 33.5L562.641 23.5C562.341 18 561.641 12.4 561.041 11C559.541 7.7 553.941 2.4 550.441 1.1C548.841 0.399997 539.541 -3.08454e-06 528.441 -3.08454e-06C509.841 -3.08454e-06 509.141 0.0999969 505.241 2.5Z"/>
                <path d="M1061.34 93.2C1058.84 93.9 1055.64 96 1052.64 99C1048.94 102.8 1047.74 104.8 1046.84 109.1C1045.94 113.1 1045.74 156.4 1045.94 276L1046.24 437.5L1066.74 440.7C1078.04 442.5 1107.94 447.4 1133.24 451.5C1158.54 455.6 1179.54 459 1180.04 459C1180.44 459 1180.74 452.5 1180.74 444.5V430H1158.64H1136.54L1133.64 427.1C1130.74 424.2 1130.74 424.1 1130.74 412.2C1130.74 400.7 1130.84 400 1133.34 397.1L1135.84 394L1158.04 393.8L1180.24 393.5L1180.54 369.7L1180.74 346H1158.64H1136.54L1133.64 343.1C1130.74 340.2 1130.74 340.1 1130.74 328C1130.74 315.9 1130.74 315.8 1133.64 312.9L1136.54 310H1158.64H1180.74V287.5V265H1159.74C1130.74 265 1130.74 265 1130.74 247.1C1130.74 228.3 1131.14 228 1160.14 228H1180.74V204.7C1180.64 192 1180.24 179.6 1179.84 177.2C1178.64 171 1174.34 165.5 1168.44 162.5C1163.34 160 1163.34 160 1135.54 160H1107.74V135.7C1107.74 121.6 1107.24 109.9 1106.64 107.6C1105.04 101.8 1098.54 95.3 1092.44 93.5C1086.54 91.8 1067.54 91.6 1061.34 93.2Z"/>
                <path d="M148.241 196.4C147.741 196.7 146.041 197.1 144.541 197.4C141.141 198.2 135.141 203.3 133.041 207.3C132.241 208.9 131.441 213.1 131.441 216.6C131.241 222.7 131.141 222.8 128.741 222.4C122.441 221.3 41.7408 212.8 37.6408 212.8C31.5408 212.8 25.4408 216 22.0408 221.2C19.8408 224.5 19.2408 226.7 18.9408 232.4C18.6408 238.9 18.8408 239.9 21.5408 244.1C26.6408 252.1 27.2408 252.2 80.2408 258C106.141 260.8 128.141 263.3 129.241 263.5C131.241 263.9 131.241 264.6 130.941 310.6L130.741 357.2L136.441 356.6C149.341 355.2 176.041 354 206.541 353.3L238.741 352.7V340.1C238.741 326.1 237.741 321.1 234.041 316.1C229.741 310.4 225.141 309 210.541 309H197.741V289.9V270.8L201.041 271.3C210.741 273 236.841 275.2 240.041 274.6C242.141 274.2 245.441 272.5 247.441 270.7C257.141 262.2 257.241 246 247.541 238.6C243.341 235.5 238.741 234.4 220.241 232.5C212.041 231.7 203.541 230.7 201.541 230.4L197.741 229.8V220.5C197.741 210.1 196.841 206.8 192.941 202.7C187.541 197 185.741 196.6 166.741 196.3C157.141 196.2 148.841 196.2 148.241 196.4Z"/>
                <path d="M352.241 243.9C344.241 245.9 336.741 251.9 332.941 259.5C331.841 261.7 329.841 269.1 328.441 276C322.941 302.7 312.641 351.6 312.141 353.1C311.741 354.5 313.941 354.8 329.941 355.4C411.641 358.5 561.741 371.6 674.241 385.5C680.841 386.3 686.741 387 687.441 387C689.541 387 689.341 270.3 687.241 263.3C685.341 256.9 678.541 249.1 672.041 245.9L667.241 243.5L511.241 243.3C425.441 243.3 353.941 243.5 352.241 243.9Z"/>
                <path d="M782.241 252.1C777.741 253.4 772.241 258.4 769.841 263.3C767.841 267.4 767.741 269.2 767.741 298.8V330H746.741H725.741V360.9V391.7L739.041 393.5C831.741 405.5 997.941 429.1 1014.04 432.6L1016.84 433.2L1016.54 392.8C1016.24 354.3 1016.14 352.3 1014.14 347.9C1011.14 341.5 1006.24 336.3 1000.34 333.2C995.741 330.8 993.941 330.6 981.241 330.2L967.241 329.8V316.6C967.241 304.4 967.041 303 964.641 298.5C963.141 295.5 960.441 292.3 957.841 290.5L953.541 287.5H918.841H884.141L879.941 290.2C877.741 291.7 874.541 295.1 873.041 297.7C870.341 302.3 870.241 303 870.241 316.2V330H849.541H828.741V298.7C828.741 270.7 828.541 267.1 826.841 263.5C824.541 258.4 818.941 253.4 814.041 252.1C809.541 250.8 786.541 250.8 782.241 252.1Z"/>
                <path d="M1330.64 303.9C1326.14 305.3 1322.74 308.1 1320.24 312.5C1318.04 316.2 1317.74 317.8 1317.74 326.8C1317.74 333.1 1317.34 337 1316.74 337C1312.74 337 1277.54 341.2 1274.44 342C1269.74 343.3 1263.84 348.4 1262.14 352.7C1258.84 360.5 1261.44 372 1267.64 377.3C1273.24 382 1276.64 382.3 1297.34 380C1307.64 378.9 1316.44 378 1316.84 378C1317.34 378 1317.74 383.1 1317.74 389.4V400.8L1286.54 401.2C1256.54 401.5 1255.04 401.6 1250.34 403.8C1242.84 407.2 1237.44 412.2 1233.64 419.2L1230.24 425.5L1229.94 446.6L1229.64 467.6L1248.94 470.9C1300.54 479.7 1414.04 500 1445.54 506C1451.24 507.1 1456.04 508 1456.34 508C1456.54 508 1456.74 490.3 1456.74 468.7C1456.74 424 1456.54 422.5 1449.44 413.7C1446.44 410.1 1443.14 407.5 1438.34 405L1431.44 401.5L1407.64 401.1L1383.74 400.8V385.6V370.5L1432.54 365.1C1459.74 362.1 1482.74 359.1 1484.74 358.3C1492.54 355.1 1496.74 348.6 1496.74 339.8C1496.74 333.3 1494.14 327.3 1489.84 323.7C1483.54 318.4 1483.34 318.4 1433.64 323.9C1408.54 326.7 1387.04 329 1385.94 329C1383.94 329 1383.74 328.5 1383.74 322.8C1383.74 315.3 1380.64 309.4 1375.04 305.8C1371.44 303.6 1370.24 303.5 1352.24 303.3C1341.84 303.2 1332.04 303.5 1330.64 303.9Z"/>
                <path d="M175.741 390.7C119.641 392.9 67.9409 400.6 38.1409 411.1C20.2409 417.4 -1.0591 430.1 0.0409036 433.8C0.840904 436.8 44.8409 537.5 45.7409 538.4C46.2409 538.9 51.1409 538.1 56.9409 536.7C157.141 511.8 351.241 516.3 646.741 550.6C874.941 577 1185.14 625.5 1472.24 679.6C1494.24 683.8 1513.34 687.2 1514.54 687.3C1516.84 687.5 1517.44 685.6 1534.04 625.7C1543.34 591.8 1550.84 563.8 1550.64 563.6C1549.94 563 1468.94 547.6 1400.24 535.1C1083.84 477.5 785.941 433 561.741 410C463.341 400 383.241 394 304.241 391C281.141 390.1 195.641 389.9 175.741 390.7Z"/>
                <path d="M165.741 559C132.841 561.1 108.241 563.7 86.7409 567.6C78.9409 569 61.6409 573 60.9409 573.5C60.7409 573.6 63.1409 579.3 66.1409 586.1C69.1409 592.9 86.2409 632.2 104.241 673.5C126.241 724.1 138.141 750.1 140.541 753.3C144.941 759.1 154.041 765.4 161.041 767.5C165.541 768.8 239.741 769 818.241 769C1461.14 769 1470.54 769 1476.04 767.1C1485.94 763.7 1493.64 757.2 1499.04 747.8C1501.44 743.7 1507.44 724.4 1506.64 723.6C1506.44 723.4 1489.14 720 1468.24 716.1C1134.04 653.7 810.641 604.3 570.241 579C486.641 570.3 419.641 564.8 342.241 560.4C310.341 558.6 187.941 557.6 165.741 559Z"/> </svg> </div>
            <i v-if="cluster.pinned" class="icon icon-pin cluster-pin-icon"/></div>
        </template>


<style lang="scss" scoped>

  .cluster-icon-menu {
    position: relative;
    align-items: center;
    display: flex;
    height: 28px;
    justify-content: center;
    width: 42px;
  }
  .cluster-pin-icon {
    position: absolute;
    top: -6px;
    right: -4px;
    font-size: 12px;
    transform: scaleX(-1);
    color: var(--body-text);
  }

  .cluster-local-logo {
    width: 20px;
  }

  .cluster-badge-logo {
    width: 42px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    background: transparent;
    border-radius: 5px;
    font-size: 12px;
    text-transform: uppercase;

    &.disabled {
      filter: grayscale(1);
      color: var(--muted);
    }
  }
</style>
